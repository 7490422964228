import * as React from 'react';
import { useWindowSize } from 'react-use';
import { Flex, Text, Image, BoxProps, AspectRatioBox } from '@chakra-ui/core';
import { Parallax } from 'react-scroll-parallax';
import wendy from '../../images/wendy.png';
import mobileImage from '../../images/meet-the-chefs-mobile.jpg';
import tabletImage from '../../images/meet-the-chefs-tablet.jpg';
import desktopImage from '../../images/meet-the-chefs-desktop.jpg';
import {
  IntersectionObserverProvider,
  useIntersectionObserverContext,
} from '../IntersectionObserver.Provider';

const chefs = [
  {
    name: 'Chef Wendy Zeng',
    paragraphs: [
      `Born in the city of Chengdu, China, chef Wendy Zeng spent her formative years training in her grandfather’s kitchen. Immersed in the flavors and techniques of the Sichuan Province, Wendy mastered the intricacies of the region’s diverse cuisine. At the age of 10, she moved to Memphis, Tennessee, where she discovered Southern BBQ and soul food. She realized that, similar to Sichuan cuisine, Southern food is about family, history, and the dishes that bring us together.`,
      `Her culinary style weaves together the flavors and techniques of her familial roots with LA’s local influences. As the host of thematic pop-ups and cannabis-infused supper clubs, she brings the values of her childhood to every table, fostering community among cannabis and food lovers alike.`,
    ],
    imagePosition: 'top',
    imageUrl: wendy,
    containerProps: {
      bg: 'lavender',
      mt: [0, 0, 0, 5],
      ml: [0, 0, 0, '10rem'],
    },
    fadeDelayTime: 2,
  },
];

interface ChefProfilePropsInterface {
  name: string;
  paragraphs: string[];
  imagePosition: string;
  containerProps: BoxProps;
  imageUrl: string;
  fadeDelayTime: number;
}

interface ImagePositionMapInterface {
  [key: string]: {
    [key: string]: number[];
  };
}

const imagePositionMap: ImagePositionMapInterface = {
  left: {
    left: [0, 0, 0, -230],
    top: [0, 0, 0, 0],
  },
  right: {
    right: [0, 0, 0, -200],
    top: [0, 0, 0, -30],
  },
  top: {
    right: [-0],
    top: [0, 0, 0, -200],
  },
};

const ChefProfileWrapper: React.FC<any> = ({ chef }) => {
  return (
    <IntersectionObserverProvider
      reset
      key={chef.name}
      position="relative"
      flexDirection={['row', 'row', 'row', 'column']}
      width={['100%', '100%', '100%', 0.3]}
      mb={[0, 0, 0, 0]}
      mx={[0, 0, 0, 20]}
      p={[4, 4, 10, 10]}
      {...chef.containerProps}
    >
      <ChefProfile {...chef} />
    </IntersectionObserverProvider>
  );
};

const ChefProfile: React.FC<ChefProfilePropsInterface> = ({
  name,
  imageUrl,
  paragraphs,
  imagePosition = 'right',
  fadeDelayTime,
}) => {
  const { inView } = useIntersectionObserverContext();
  return (
    <Flex
      width={['100%']}
      opacity={inView ? 1 : 0}
      transition={`.5s ease-in-out opacity .${fadeDelayTime}s`}
    >
      <Image
        src={imageUrl}
        position={['initial', 'initial', 'initial', 'absolute']}
        borderRadius="50%"
        width={[100, 100, 150, 250]}
        height={[100, 100, 150, 250]}
        mr={[5, 10, 10, 0]}
        ml={[2, 2, 10, 0]}
        {...imagePositionMap[imagePosition]}
      />
      <Flex flexDirection={['column']} width={['100%']} pr={[2, 2, 5, 0]}>
        <Text
          color="white"
          fontFamily="heading"
          fontSize={['md', 'lg', 'xl', '2xl']}
        >
          {name}
        </Text>
        {paragraphs.map((paragraph, pIndex) => (
          <Text
            key={paragraph}
            mt={pIndex === 0 ? 2 : [4, 8]}
            color="white"
            fontFamily="body"
            fontSize={['sm', 'md']}
            lineHeight={[1.1]}
          >
            {paragraph}
          </Text>
        ))}
      </Flex>
    </Flex>
  );
};

export const MeetTheChefs = () => {
  const { width } = useWindowSize();

  return (
    <Flex
      width={['100%']}
      p={[0, 0, 0, 20]}
      flexDirection={['column']}
      id="about"
      backgroundImage={[
        `url(${mobileImage})`,
        `url(${mobileImage})`,
        `url(${tabletImage})`,
        `url(${desktopImage})`,
      ]}
      pt={[
        'calc(350 / 640  * 100%)',
        'calc(350 / 640  * 100%)',
        'calc(510 / 1024  * 100%)',
        'calc(1920 / 2021  * 100%)',
        '100px',
      ]}
      pb={[0, 0, 0, 200]}
      backgroundSize={['100%', '100%', '100%', 'cover']}
      backgroundPosition={[
        'top center',
        'top center',
        'top center',
        'center 50%',
      ]}
      minHeight={['100vh']}
      backgroundRepeat="no-repeat"
      position="relative"
    >
      <Text
        textAlign={['center', 'center', 'center', 'left']}
        fontSize={['3xl', '3xl', '4xl', '6xl']}
        color="coral"
        fontFamily="heading"
        textTransform="uppercase"
        whiteSpace="nowrap"
        position={['absolute', 'absolute', 'absolute', 'relative']}
        top={5}
        left="50%"
        transform="translateX(-50%)"
      >
        Meet the chef
      </Text>
      <Parallax y={[-10, 15]} disabled={width < 1000}>
        <Flex
          width={['100%']}
          flexDirection={['column', 'column', 'column', 'row']}
          flexWrap={['nowrap', 'nowrap', 'nowrap', 'wrap']}
          mt={[10, 20, 40, 100]}
          alignItems={['center']}
          justifyContent={['center']}
        >
          {chefs.map((chef, index) => (
            <ChefProfileWrapper chef={chef} key={chef.name} />
          ))}
        </Flex>
      </Parallax>
    </Flex>
  );
};
