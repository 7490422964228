import * as React from 'react';
import { Flex, Image } from '@chakra-ui/core';
import mobileHero from '../../images/hero-mobile.jpg';
import tabletHero from '../../images/hero-tablet.jpg';
import desktopHero from '../../images/hero-desktop.jpg';
import elevatedCateringTextImage from '../../images/elevated-catering.png';
import { Blurb } from './Blurb';

export const Hero: React.FC<{}> = () => {
  return (
    <Flex
      width={['100%']}
      bg="beige"
      alignItems={['flex-start', 'flex-start', 'flex-start', 'center']}
      justifyContent={['center']}
      pt={[
        `calc(980 / 640 * 100%)`,
        `calc(680 / 640 * 100%)`,
        `calc(1350 / 1920 * 100%)`,
        `calc(1350 / 1920 * 100%)`,
      ]}
      pb={[10, 0]}
      backgroundSize={['cover', 'cover', 'cover', 'cover']}
      backgroundRepeat="no-repeat"
      backgroundImage={[
        `url(${mobileHero})`,
        `url(${mobileHero})`,
        `url(${tabletHero})`,
        `url(${desktopHero})`,
      ]}
      backgroundPosition={['top center', 'center center']}
      position="relative"
    >
      <Image
        src={elevatedCateringTextImage}
        position="absolute"
        top={['15%', '15%', '35%']}
        maxW={[500, 500, 500, 800]}
        width={['100%']}
        p={5}
      />
      <Blurb />
    </Flex>
  );
};
