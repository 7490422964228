import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import { Hero } from '../components/Home/Hero';
import { NowServing } from '../components/Home/NowServing';
import { MeetTheChefs } from '../components/Home/MeetTheChefs';
import { Contact } from '../components/Home/Contact';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero />
    <NowServing />
    <MeetTheChefs />
    <Contact />
  </Layout>
);

export default IndexPage;
