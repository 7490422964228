import * as React from 'react';
import { Flex, Text, BoxProps } from '@chakra-ui/core';
import image from '../../images/now-serving-mobile.jpg';
import imageDesktop from '../../images/now-serving-desktop.jpg';

const Heading: React.FC<BoxProps> = props => (
  <Flex width={['auto', 'auto', 'auto', 260]} mr={[0, 0, 0, 10]}>
    <Text
      fontSize={['2xl', '2xl', '3xl', '4xl']}
      fontFamily="heading"
      color="white"
      textAlign={['center', 'center', 'center', 'left']}
      lineHeight={1}
      mt={[4, 4, 8, 0]}
      {...props}
    />
  </Flex>
);

const Body: React.FC<BoxProps> = props => (
  <Flex
    width={['auto', 'auto', 'auto', 400]}
    justifyContent={['center', 'center', 'center', 'flex-start']}
  >
    <Text
      fontFamily="body"
      color="white"
      textAlign={['center', 'center', 'center', 'left']}
      maxWidth={['100%', '100%', '75%', '100%']}
      lineHeight={1.2}
      {...props}
    />
  </Flex>
);

const ServiceContainer: React.FC<{}> = props => (
  <Flex
    mt={[0, 0, 0, 8]}
    flexDirection={['column', 'column', 'column', 'row']}
    width={['100%']}
    alignItems={['center', 'center', 'center', 'flex-start']}
    {...props}
  />
);

export const NowServing: React.FC<{}> = () => {
  return (
    <Flex
      width={['100%']}
      p={[0, 0, 0, 20]}
      alignItems={['flex-start']}
      justifyContent={['flex-end']}
      flexDirection={['column', 'column', 'column', 'row']}
      backgroundImage={['none', 'none', 'none', `url(${imageDesktop})`]}
      backgroundSize="cover"
    >
      {/* Mobile only */}
      <Flex
        width={['100%']}
        justifyContent={['center']}
        height={550}
        p={10}
        display={['flex', 'flex', 'flex', 'none']}
        backgroundPosition="center center"
        backgroundImage={`url(${image})`}
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
      >
        <Text
          fontSize={['3xl', '4xl', '5xl', 80]}
          color="white"
          fontFamily="heading"
          textTransform="uppercase"
        >
          Now Serving
        </Text>
      </Flex>
      <Flex
        width={['100%', '100%', '100%', '45%']}
        px={[3, 5, 20, 10]}
        py={[20, 20, 20, 10]}
        bg="coral"
        flexDirection={['column']}
        alignItems={['center', 'center', 'center', 'flex-start']}
        id="services"
        height="auto"
      >
        <Text
          fontSize={['3xl', '6xl', '6xl', '6xl']}
          display={['none', 'none', 'none', 'initial']}
          color="turquoise"
          fontFamily="heading"
          textTransform="uppercase"
        >
          Now Serving
        </Text>
        <Text
          color="white"
          fontSize={['lg', 'lg', 'lg', 'xl']}
          lineHeight={1.2}
          fontWeight="bold"
          fontFamily="body"
          pr={[0, 0, 0, 20]}
          textAlign={['center', 'center', 'center', 'left']}
          maxWidth={['90%', '90%', '75%', '100%']}
        >
          From intimate in-home dinners, to lavish weddings, and big corporate
          gatherings, let us drizzle a little magic on your next event!
        </Text>
        <ServiceContainer>
          <Heading color="yellow">Full-service Catering</Heading>
          <Body>
            Lose yourself in good company and great conversation while we make
            sure everything runs smoothly. We create custom menus, book rentals,
            provide service staff, and prepare on site for the freshest aromatic
            experience.
          </Body>
        </ServiceContainer>
        <ServiceContainer>
          <Heading color="pink">Drop-off Catering</Heading>
          <Body>
            Keeping it casual? For events that don’t require staff, we deliver
            and set up elevated meals designed for easy clean up.
          </Body>
        </ServiceContainer>
        <ServiceContainer>
          <Heading color="mint">Weekly Meal Prep Delivery</Heading>
          <Body>
            Out with cookie-cutter food delivery; in with healthy and
            sustainable meals made fresh by our chefs. Sign up for our meal
            delivery plans and get Drizzle delivered to your home or office.
          </Body>
        </ServiceContainer>
        <ServiceContainer>
          <Heading color="turquoise" id="infused">
            Infused Events
          </Heading>
          <Body>
            From brand activations to educational events and lively parties, we
            source from trusted partners and apply precision dosage for
            guaranteed good times. We offer THC or CBD infusions, using the
            whole cannabis plant to fit your vibe.
          </Body>
        </ServiceContainer>
      </Flex>
    </Flex>
  );
};
