import * as React from 'react';
import { Flex, Text } from '@chakra-ui/core';

export const Blurb: React.FC<{}> = () => {
  return (
    <Flex
      width={['100%']}
      p={[2, 2, 20, 20]}
      bg="transparent"
      alignItems={['center']}
      justifyContent={['center']}
      position="absolute"
      bottom={[5, 5, 0]}
    >
      <Text
        color="turquoise"
        maxWidth={['100%', '100%', '100%', '60%']}
        lineHeight={1}
        fontFamily="heading"
        textAlign="center"
        fontSize={['sm', 'xl', 'xl', '2xl']}
      >
        Drizzle celebrates diversity in cooking with dishes inspired by LA’s
        rich cultural landscape. We make food to expand your palette and your
        mind.
      </Text>
    </Flex>
  );
};
