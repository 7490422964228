import * as React from 'react';
import * as yup from 'yup';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Text,
  Link,
  BoxProps,
  Checkbox,
  Textarea,
  Box,
  Image,
  useDisclosure,
} from '@chakra-ui/core';
import { Formik, Field, Form } from 'formik';
import { DrizzleErrorMessage } from '../Footer';

import { Button } from '../Button';
import image from '../../images/drop-us-a-line.gif';

const schema = yup.object().shape({
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  areaOfInterest: yup
    .array()
    .of(yup.string())
    .required('Please select at least one option'),
  phone: yup.string().required('Required'),
  email: yup
    .string()
    .email()
    .required('Required'),
  message: yup.string().required('Required'),
});

interface FormSectionLabelPropsInterface extends BoxProps {
  label: string;
}

const FormSectionLabel: React.FC<FormSectionLabelPropsInterface> = ({
  label,
  ...props
}) => (
  <Flex width={['100%']} {...props}>
    <Text color="black">{label}</Text>
  </Flex>
);

interface FormInputPropsInterface extends BoxProps {
  label?: string;
  id: string;
}

const FormInput: React.FC<FormInputPropsInterface> = ({
  label,
  id,
  ...props
}) => (
  <Field
    name={id}
    render={({ field, form }) => (
      <FormControl
        width={['100%']}
        isInvalid={form.errors.name && form.touched.name}
        {...props}
      >
        <Flex flexDirection={['column']}>
          <Input
            {...field}
            id={id}
            bg="inputBg"
            border={1}
            borderStyle="solid"
            borderColor="inputBorder"
          />
          {label && (
            <FormLabel fontSize={[10]} htmlFor={id} color="black">
              {label}
            </FormLabel>
          )}
          <DrizzleErrorMessage name={id} />
        </Flex>
      </FormControl>
    )}
  />
);

const encode = data => {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
};

export const Contact: React.FC<{}> = () => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const handleSubmitContactForm = async (
    values,
    { setSubmitting, resetForm },
  ) => {
    const { areaOfInterest, ...restValues } = values;

    const transformedValues = {
      ...restValues,
      'areaOfInterest[]': areaOfInterest.reduce((accum, item, index) => {
        if (index === 0) {
          return item;
        }
        return `${accum}, ${item}`;
      }, ''),
    };

    const body = encode({
      'form-name': 'drizzleContactForm',
      ...transformedValues,
    });

    try {
      const response = await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body,
      });
      if (response.status === 200) {
        onOpen();
        resetForm();
        setSubmitting(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Flex
      id="contact"
      width={['100%']}
      py={[8, 8, 8, 20]}
      px={[0, 8, 8, 20]}
      bg="lavender"
      flexDirection={['column']}
    >
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg="turquoise">
          <ModalHeader fontFamily="body">Message Received!</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontFamily="body">
            Thank you for your interest! We will be in touch shortly.
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Text
        fontSize={['4xl', '4xl', '6xl', '6xl']}
        color="white"
        fontFamily="heading"
        textTransform="uppercase"
        textAlign={['center', 'center', 'center', 'left']}
      >
        Contact
      </Text>
      <Flex
        width={['100%']}
        px={[4, 4, 4, 0]}
        py={[0, 4, 4, 10]}
        flexDirection={['column', 'column', 'column', 'row']}
      >
        <Flex flexDirection={['column']} width={['100%']}>
          <Text
            color="white"
            fontSize={['xl', 'xl', '3xl', '4xl']}
            fontFamily="heading"
            textAlign={['center', 'center', 'center', 'left']}
          >
            Drop us a line.
          </Text>
          <Text
            color="black"
            mt={8}
            display={['none', 'none', 'none', 'initial']}
            maxW={['100%', '100%', '100%', '85%']}
            fontFamily="body"
          >
            Contact us for any inquiries concerning catering, private parties,
            wine dinners, food styling, menu development, and any other food
            related opportunties.
          </Text>
          <Link
            href="mailto:info@drizzle.catering"
            mt={8}
            color="black"
            display={['none', 'none', 'none', 'initial']}
            fontFamily="body"
          >
            info@drizzle.catering
          </Link>
          <Link
            href="tel:4242802357"
            mt={4}
            color="black"
            display={['none', 'none', 'none', 'initial']}
            fontFamily="body"
          >
            (424) 280-2357
          </Link>
          <Flex
            width={['100%']}
            maxW={['100%', '100%', '100%', '85%']}
            justifyContent={['center', 'center', 'center']}
            alignItems={['center']}
            flexDirection={['column']}
            py={5}
          >
            <Image
              src={image}
              borderRadius="50%"
              width={[250, 250, 250, 400]}
              height={[250, 250, 250, 400]}
              border={5}
              borderStyle="solid"
              borderColor="gold"
            />
            <Link
              href="mailto:info@drizzle.catering"
              mt={8}
              color="black"
              display={['initial', 'initial', 'initial', 'none']}
              fontFamily="body"
            >
              info@drizzle.catering
            </Link>
            <Link
              href="tel:4242802357"
              mt={4}
              color="black"
              display={['initial', 'initial', 'initial', 'none']}
              fontFamily="body"
            >
              (424) 280-2357
            </Link>
          </Flex>
        </Flex>
        <Box width={['100%']}>
          <Formik
            validationSchema={schema}
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              phone: '',
              areaOfInterest: [],
              message: '',
            }}
            onSubmit={handleSubmitContactForm}
          >
            {({ handleSubmit }) => (
              <Form
                onSubmit={handleSubmit}
                name="drizzleContactForm"
                action="thank-you"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
              >
                <Flex
                  flexDirection={['column']}
                  width={['100%']}
                  mt={[10, 0, 0, 0]}
                  px={[2, 2, 20, 0]}
                >
                  <Flex width={['100%']} flexDirection={['column']}>
                    <FormSectionLabel label="Name*" />
                    <Flex>
                      <FormInput label="First Name" id="firstName" />
                      <FormInput label="Last Name" id="lastName" ml={3} />
                    </Flex>
                    <FormSectionLabel label="Email Address*" mt={8} />
                    <FormInput id="email" />
                    <FormSectionLabel label="Phone*" mt={8} />
                    <FormInput id="phone" />
                    <Flex flexDirection={['column']} mt={[8]}>
                      <FormLabel color="black" fontFamily="body" pb={0}>
                        Area of Interest
                      </FormLabel>
                      <Text fontSize="xs" color="gray.600" fontFamily="body">
                        Select all that apply
                      </Text>
                      <DrizzleErrorMessage name="areaOfInterest" />
                      <Field
                        name="areaOfInterest"
                        render={({ field, form }) => (
                          <FormControl mt={4}>
                            <Checkbox
                              {...field}
                              value="Event Catering"
                              isChecked={field.value.includes('Event Catering')}
                              borderColor="inputBorder"
                              color="black"
                              fontFamily="body"
                              fontSize="xs"
                              name="areaOfInterest[]"
                              onChange={e => {
                                const currentFieldValues = field.value;
                                if (
                                  !currentFieldValues.includes(e.target.value)
                                ) {
                                  form.setFieldValue(field.name, [
                                    ...currentFieldValues,
                                    e.target.value,
                                  ]);
                                } else {
                                  form.setFieldValue(
                                    field.name,
                                    currentFieldValues.filter(
                                      value => value !== e.target.value,
                                    ),
                                  );
                                }
                              }}
                            >
                              <Text fontFamily="body" fontSize="xs">
                                Event Catering
                              </Text>
                            </Checkbox>
                          </FormControl>
                        )}
                      />
                      <Field
                        name="areaOfInterest"
                        render={({ field, form }) => (
                          <FormControl>
                            <Checkbox
                              {...field}
                              value="Menu Development Consulting"
                              isChecked={field.value.includes(
                                'Menu Development Consulting',
                              )}
                              borderColor="inputBorder"
                              color="black"
                              name="areaOfInterest[]"
                              onChange={e => {
                                const currentFieldValues = field.value;
                                if (
                                  !currentFieldValues.includes(e.target.value)
                                ) {
                                  form.setFieldValue(field.name, [
                                    ...currentFieldValues,
                                    e.target.value,
                                  ]);
                                } else {
                                  form.setFieldValue(
                                    field.name,
                                    currentFieldValues.filter(
                                      value => value !== e.target.value,
                                    ),
                                  );
                                }
                              }}
                            >
                              <Text fontFamily="body" fontSize="xs">
                                Menu Development/Consultation
                              </Text>
                            </Checkbox>
                          </FormControl>
                        )}
                      />
                      <Field
                        name="areaOfInterest"
                        render={({ field, form }) => (
                          <FormControl>
                            <Checkbox
                              {...field}
                              value="Cooking Classes"
                              isChecked={field.value.includes(
                                'Cooking Classes',
                              )}
                              name="areaOfInterest[]"
                              onChange={e => {
                                const currentFieldValues = field.value;
                                if (
                                  !currentFieldValues.includes(e.target.value)
                                ) {
                                  form.setFieldValue(field.name, [
                                    ...currentFieldValues,
                                    e.target.value,
                                  ]);
                                } else {
                                  form.setFieldValue(
                                    field.name,
                                    currentFieldValues.filter(
                                      value => value !== e.target.value,
                                    ),
                                  );
                                }
                              }}
                              borderColor="inputBorder"
                              color="black"
                              fontFamily="body"
                              fontSize="xs"
                            >
                              <Text fontFamily="body" fontSize="xs">
                                Cooking Classes (Private or Group)
                              </Text>
                            </Checkbox>
                          </FormControl>
                        )}
                      />
                    </Flex>
                    <FormSectionLabel label="Message*" mt={8} />
                    <Field
                      name="message"
                      render={({ field, form }) => (
                        <Textarea
                          {...field}
                          mt={2}
                          fontFamily="body"
                          bg="inputBg"
                          border={1}
                          borderStyle="solid"
                          borderColor="inputBorder"
                        />
                      )}
                    />
                    <DrizzleErrorMessage name="message" />
                    <Flex
                      width={['100%']}
                      justifyContent={[
                        'center',
                        'center',
                        'center',
                        'flex-start',
                      ]}
                    >
                      <Button type="submit" mt={8}>
                        Submit
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              </Form>
            )}
          </Formik>
        </Box>
      </Flex>
    </Flex>
  );
};
